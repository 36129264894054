const ROUTER_PATH = {
  auth: 'auth',
  home: 'home',
  editAdd: 'edit-add',
  editJobInfo: 'edit-job-info',
  importExport: 'import-export',
  viewInfo: 'view-info',
  reportLabels: 'reports-labels',
  settings: 'settings',
  viewLoadInformation: 'view-load-information',
  browseLoads: 'browse-loads',
  viewLoadCriteria: 'view-loads-criteria',
  piecemarkInfo: 'piecemark-info',
  statusSummery: 'status-summery',
  editCustomerInformation: 'edit-customer-information',
  editEmployeeInformation: 'edit-employee-information',
  editEmployeeClassInfo: 'edit-employee-class-info',
  editRoutingCodes: 'edit-routing-codes',
  editCarrierInformation: 'edit-carrier-information',
  divisionManagement: 'division-management',
  logonAccessManagement: 'logon-access-management',
  applicationPermissions: 'application-permissions',
  viewLog: 'view-log',
  shipIdNumbers: 'ship-id-numbers',
  viewLogFilters: 'log-filters',
  printSelected: 'print-selected',
  mcLabelDestination: 'mc-label-destinations',
  licenseManagement: 'license-management',
  barCodePrinterPrefs: 'barcode-printer-prefs',
  editStatusCodes: 'edit-status-codes',
  kissImport: 'kiss-import',
  kissImportDeletion: 'kiss-import-deletion',
  tableSettings: 'table-settings',
  viewLogonLicensesInfo: 'view-logon-licenses-info',
  barcodeIdLabel: 'barcode-id-label',
  rawMaterialLabels: 'raw-material-labels',
  activeRecordDeletes: 'active-record-deletes',
  deletedRecords: 'recall-delete-records',
  jobPiecemarkRecall: 'job-piecemark-recall',
  preferences: 'preferences',
  piecemarkEntry: 'piecemark-entry',
};

export default ROUTER_PATH;
