const ENDPOINTS = {
  auth: {
    loginFields: 'login/fields',
    login: 'login',
    logout: 'logout',
    register: 'signup',
  },
  jobs: {
    root: 'jobs',
    refDivision: 'jobs/refs/divisions',
    labelNames: 'jobs/refs/label_names',
    labeLaseNames: 'jobs/refs/labelase_templates',
    addresses: 'refs/addresses',
    numbers: 'jobs/refs/numbers',
    customers: 'jobs/refs/customers',
    refLoads: 'jobs/refs/loads',
    defaults: 'jobs/refs/defaults',
  },
  logs: {
    root: 'logs',
    export: 'logs/export',
    jobs: 'logs/refs/job_headers',
    load: 'logs/refs/load_numbers',
  },
  loads: {
    root: 'loads',
    refs: 'loads/refs/loads',
    piecemarks: 'loads/reports/loads/piecemarks',
    loads: 'loads/reports/loads',
    reportsTop: 'loads/reports/loads/pcmk_instances',
    statusSummary: 'loads/reports/loads/status_summary',
    reportsBottom: 'loads/reports/transactions',
    refsJobs: 'loads/refs/job_headers',
    refsLocations: 'loads/refs/locations',
    refsParentPiecemarks: 'loads/refs/parent_piecemarks',
    refsStatuses: 'loads/refs/status_codes',
    refsBatches: 'loads/refs/batches',
    refsBundleNumbers: 'loads/refs/bundle_numbers',
    refsCowCodes: 'loads/refs/cow_codes',
    refsIdSerialNumbers: 'loads/refs/id_serial_numbers',
    refsLoadNumbers: 'loads/refs/load_numbers',
    refsLoadReleases: 'loads/refs/load_releases',
    refsLotNumbers: 'loads/refs/lot_numbers',
    refsPcReleases: 'loads/refs/pc_releases',
    refsPkgNumbers: 'loads/refs/pkg_numbers',
    refsSqcNumbers: 'loads/refs/sequence_numbers',
    refsSheetNumbers: 'loads/refs/sheet_numbers',
    refsShopOrderNumbers: 'loads/refs/shop_order_numbers',
  },
  shipping: {
    jobs: 'transactions/shipping/refs/job_headers',
    load_numbers: 'transactions/shipping/refs/load_numbers',
    table: 'transactions/shipping/reports',
    viewTable: 'transactions/shipping/reports',
    status_codes: 'transactions/shipping/refs/status_codes',
    interim_load: 'transactions/shipping/refs/loads/allow_interim_shipments',
    loads_get: 'transactions/shipping/refs/loads',
    loadCreate: 'transactions/shipping/loads',
    statistic: 'transactions/shipping/refs/loads/statistics',
    shipping: 'transactions/shipping',
  },
  recallDeleteRecords: {
    root: 'deleted_records',
    recallable: 'deleted_records/recallable',
    refs: 'deleted_records/loads',
    piecemarks: 'deleted_records/reports/loads/piecemarks',
    loads: 'deleted_records/reports/loads',
    reportsTop: 'deleted_records/reports/loads/pcmk_instances',
    statusSummary: 'deleted_records/reports/loads/status_summary',
    reportsBottom: 'deleted_records/reports/transactions',
    refsJobs: 'deleted_records/refs/job_headers',
    refsLocations: 'deleted_records/refs/locations',
    refsParentPiecemarks: 'deleted_records/refs/parent_piecemarks',
    refsStatuses: 'deleted_records/refs/status_codes',
    refsBatches: 'deleted_records/refs/batches',
    refsBundleNumbers: 'deleted_records/refs/bundle_numbers',
    refsCowCodes: 'deleted_records/refs/cow_codes',
    refsIdSerialNumbers: 'deleted_records/refs/id_serial_numbers',
    refsLoadNumbers: 'deleted_records/refs/load_numbers',
    refsLoadReleases: 'deleted_records/refs/load_releases',
    refsLotNumbers: 'deleted_records/refs/lot_numbers',
    refsPcReleases: 'deleted_records/refs/pc_releases',
    refsPkgNumbers: 'deleted_records/refs/pkg_numbers',
    refsSqcNumbers: 'deleted_records/refs/sequence_numbers',
    refsSheetNumbers: 'deleted_records/refs/sheet_numbers',
    refsShopOrderNumbers: 'deleted_records/refs/shop_order_numbers',
  },
  delActiveRecords: {
    root: 'active_records',
    refs: 'active_records/refs/loads',
    piecemarks: 'active_records/reports/loads/piecemarks',
    loads: 'active_records/reports/loads',
    reportsTop: 'active_records/reports/loads/pcmk_instances',
    statusSummary: 'active_records/reports/loads/status_summary',
    reportsBottom: 'active_records/reports/transactions',
    refsJobs: 'active_records/refs/job_headers',
    refsLocations: 'active_records/refs/locations',
    refsParentPiecemarks: 'active_records/refs/parent_piecemarks',
    refsStatuses: 'active_records/refs/status_codes',
    refsBatches: 'active_records/refs/batches',
    refsBundleNumbers: 'active_records/refs/bundle_numbers',
    refsCowCodes: 'active_records/refs/cow_codes',
    refsIdSerialNumbers: 'active_records/refs/id_serial_numbers',
    refsLoadNumbers: 'active_records/refs/load_numbers',
    refsLoadReleases: 'active_records/refs/load_releases',
    refsLotNumbers: 'active_records/refs/lot_numbers',
    refsPcReleases: 'active_records/refs/pc_releases',
    refsPkgNumbers: 'active_records/refs/pkg_numbers',
    refsSqcNumbers: 'active_records/refs/sequence_numbers',
    refsSheetNumbers: 'active_records/refs/sheet_numbers',
    refsShopOrderNumbers: 'active_records/refs/shop_order_numbers',
    recordDeleteable: 'active_records/deleteable',
    recordDeleteableJob: 'active_records/deleteable/job',
    recordDeleteableTransaction: 'active_records/deleteable/transactions',
  },
  piecemarkEntries: {
    root: 'piecemark_entries',
    piecemarks: 'piecemark_entries/piecemarks',
    refJobs: 'piecemark_entries/refs/jobs',
    jobs: 'piecemark_entries/jobs',
    refCowCodes: 'piecemark_entries/refs/cow_codes',
    refLoadNumbers: 'piecemark_entries/refs/load_numbers',
    refLotNumbers: 'piecemark_entries/refs/lot_numbers',
    refRoutingCodes: 'piecemark_entries/refs/routing_codes',
    refSequenceNumbers: 'piecemark_entries/refs/sequence_numbers',
    refSheetNumbers: 'piecemark_entries/refs/sheet_numbers',
    refShopOrders: 'piecemark_entries/refs/shop_orders',
    refID: 'piecemark_entries/ids',
    refMaterials: 'piecemark_entries/refs/materials',
    refFinishes: 'piecemark_entries/refs/finishes',
    refGrades: 'piecemark_entries/refs/grades',
    refFillInfo: 'piecemark_entries/refs/fill_info',
  },
  permission: {
    root: 'permission_groups',
    main: 'permissions',
  },
  customers: {
    root: 'customers',
    refNames: 'customers/refs/corp_names',
    refNumbers: 'customers/refs/numbers',
  },
  barcode: {
    root: 'printer_preferences',
    printerLabelLase: 'printer_preferences/labelase_template_names',
    printerLabels: 'printer_preferences/label_names',
    printerNames: 'printer_preferences/printer_names',
    locPath: 'printer_preferences/refs/personal_paths',
  },
  employee: {
    root: 'employee',
    refNumbers: 'employee/refs/numbers',
    associations: 'employee/refs/associations',
    classIds: 'employee/refs/codes',
  },
  employeeClass: {
    root: 'employee_classes',
    numbers: 'employee_classes/refs/codes',
    orders: 'employee_classes/refs/orders',
  },
  idLabels: {
    root: 'labels/barcodes/tables',
    refsSqcNumbers: 'labels/barcodes/refs/sequence_numbers',
    refsSheetNumbers: 'labels/barcodes/refs/sheet_numbers',
    numbers: 'labels/barcodes/refs/job_headers',
    refStatuses: 'labels/barcodes/refs/status_codes',
    refsShopOrderNumbers: 'labels/barcodes/refs/shop_order_numbers',
    refsLotNumbers: 'labels/barcodes/refs/lot_numbers',
    refsLoadNumbers: 'labels/barcodes/refs/load_numbers',
    refsPkgNumbers: 'labels/barcodes/refs/pkg_numbers',
    refsLoadReleases: 'labels/barcodes/refs/load_releases',
    refsLocations: 'labels/barcodes/refs/locations',
    refsBatches: 'labels/barcodes/refs/batches',
    refsMaterials: 'labels/barcodes/refs/materials',
    refsIdSerialNumbers: 'labels/barcodes/refs/id_serial_numbers',
    refsBundleNumbers: 'labels/barcodes/refs/bundle_numbers',
    refsParentPiecemarks: 'labels/barcodes/refs/parent_piecemarks',
    refsPcReleases: 'labels/barcodes/refs/pc_releases',
    tables: 'labels/barcodes/tables',
    tableTop: 'labels/barcodes/tables',
    tableBottom: 'labels/barcodes/transactions',
    printerPrefs: 'labels/barcodes/refs/printer_preferences',
    printerLabelLase: 'labels/barcodes/refs/printer_preferences/labelase_template_names',
    printerLabels: 'labels/barcodes/refs/printer_preferences/label_names',
    printerNames: 'labels/barcodes/refs/printer_preferences/printer_names',
    printer: 'labels/barcodes/printer',
    printerSettings: 'labels/barcodes/printer/settings',
  },
  rawMaterials: {
    root: 'labels/raw_materials/tables',
    refJobs: 'labels/raw_materials/refs/job_headers',
    refControls: 'labels/raw_materials/refs/control_numbers',
    refMaterials: 'labels/raw_materials/refs/materials',
    refSerialNumbers: 'labels/raw_materials/refs/serial_numbers',
    refLocations: 'labels/raw_materials/refs/locations',
    refHeats: 'labels/raw_materials/refs/heats',
    refPrinterPrefs: 'labels/raw_materials/refs/printer_preferences',
    refLabelNames: 'labels/raw_materials/refs/printer_preferences/label_names',
    refPrinterNames: 'labels/raw_materials/refs/printer_preferences/printer_names',
    refLabelaseTemplateNames:
      'labels/raw_materials/refs/printer_preferences/labelase_template_names',
    print: 'labels/raw_materials/printer',
    printerSettings: 'labels/raw_materials/printer/settings',
  },
  statusCodes: {
    root: 'status_codes',
    refCodes: 'status_codes/refs/codes',
    refEmployeeCodes: 'status_codes/refs/employee_codes',
    refAssociations: 'status_codes/refs/associations ',
    refProcesses: 'status_codes/refs/process_types',
    refEndFor: 'status_codes/refs/end_fors',
    customProcess: 'status_codes/refs/end_for_custom_processes',
  },
  carrier: {
    root: 'carrier',
  },
  addresses: {
    root: 'addresses',
    refTypes: 'addresses/refs/types',
  },
  addressTypes: 'addresses/refs/types',
  references: {
    cities: 'zipcodes/refs/cities',
    zip: 'zipcodes',
  },
  associations: 'associations',
  license: 'license',
  preferences: {
    root: 'preferences',
    display: {
      root: 'display_preferences',
      refLangs: 'display_preferences/refs/langs',
    },
    purchaseOrder: 'purchase_order_preferences',
    wireless: 'wireless_preferences',
    fabSuite: {
      root: 'fab_suite_preferences',
      connection: 'fab_suite_preferences/connection',
    },
    dataPaths: 'data_paths_preferences',
    hardware: {
      root: 'hardware_preferences',
      refLoadNumbers: 'hardware_preferences/refs/auto_load_number_calc_types',
      refBarcodeLabelTypes: 'hardware_preferences/refs/barcode_label_types',
    },
    materialType: {
      root: 'material_type_preferences',
      refValidateAgainstTypes: 'material_type_preferences/refs/validate_against_types',
    },
    miscInfo: {
      root: 'misc_preferences',
      refCustomerNumbers: 'misc_preferences/refs/customer_numbers',
      refInstalledAtTypes: 'misc_preferences/refs/installed_at_types',
      refRoutingCodes: 'misc_preferences/refs/routing_codes',
    },
  },
  tenant: 'tenant',
  users: {
    root: 'users',
    refEmployees: 'users/refs/employees',
    refAssociations: 'users/refs/associations',
    refNames: 'users/refs/names',
    refPrinters: 'users/refs/printers',
    refTemplates: 'users/refs/templates',
    refMobileScreenLabels: 'users/refs/mobile_screen_labels',
    refMobileScrees: 'users/refs/mobile_screens',
    refPermissionGroups: 'users/refs/permission_groups',
    foxFire: 'about/foxfire',
  },
  carriers: {
    root: 'carriers',
    refNumbers: 'carriers/refs/numbers',
  },
  routingCodes: {
    root: 'routing_codes',
    refStatusCodes: 'routing_codes/refs/status_codes',
  },
  kiss: {
    root: 'import/kiss',
    import: 'import',
    files: 'import/kiss/refs/files',
    job: 'import/kiss/refs/job',
    preferences: 'import/kiss/refs/preferences',
    records: 'import/kiss/records',
    shopOrders: 'import/kiss/refs/shop_orders',
    jobNumbers: 'import/kiss/refs/job_numbers',
    routingCodes: 'import/kiss/refs/routing_codes',
    saveDiscardTypes: 'import/kiss/refs/discard_types',
    importReportsLoads: 'import/reports/loads',
    importReportsLoadsIdFiles: 'import/reports/loads/idfiles',
    importReportsLoadsIdFileTransactions: 'import/reports/transactions',
    deleteIdFiles: 'import/deleteable',
    deleteTransactions: 'import/deleteable/transactions',
  },
  sse: {
    import: 'sse/import',
    importKiss: 'sse/import/kiss',
  },
  tablePrefs: {
    root: 'table_prefs',
  },
  viewLogonLicense: {
    root: 'view_logon_license_info',
    session: 'view_logon_license_info/session',
  },
};

export default ENDPOINTS;
