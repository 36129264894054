const ScreenId = {
  employeeInfo: 'sts.edit.btn.edit.employee.info',
  materialInfo: 'sts.edit.btn.material.type.info',
  editJobInfo: 'sts.edit.btn.edit.job.info',
  editCustomerInformation: 'sts.edit.btn.edit.customer.info',
  editCarrierInformation: 'sts.edit.btn.edit.carrier.info',
  editEmployeeClassInfo: 'sts.edit.btn.edit.employee.class.info',
  editStatusCodes: 'sts.edit.btn.edit.status.codes',
  editRoutingCodes: 'sts.edit.btn.edit.routing.codes',
  kissImport: 'sts.import.btn.kiss.import',
  barCodePrinterPrefs: 'sts.default.btn.barcode.printer',
  divisionManagement: 'sts.default.btn.division.and.plant.management',
  logonAccessManagement: 'sts.default.btn.sts.logon.management',
  mcLabelDestination: 'sts.btn.mc.label.destination',
  applicationPermission: 'sts.default.btn.application.permissions',
  receiverFormat: 'sts.import.btn.import.receiver.format',
  stdFormat: 'sts.import.btn.import.std.format',
  idWithNums: 'sts.import.btn.text.file.w.id.nums',
  idWithOutNums: 'sts.import.btn.text.file.wo.id.nums',
  receiverFormatExp: 'sts.import.btn.receiver.format.export',
  stdFormatExp: 'sts.import.btn.std.format.export',
  genFormatExp: 'sts.import.btn.generic.text.file.export',
  teklaFileExp: 'sts.import.btn.tekla.file.export',
  importSds2: 'sts.import.btn.btn_Sds2_xml_export',
  asnImport: 'sts.import.btn.asn.import',
  perfmonanceContract: 'sts.import.btn.performance.contractors',
  pfcExcel: 'sts.import.btn.timberline.pfs.excel',
  customerTeklaFileExport: 'sts.import.btn.tekla.file.export',
  stsImport: 'sts.import.btn.btn_DesignDataXSR',
  ejeDelimited: 'sts.import.btn.eje.delimited',
  piecemarkEntry: 'sts.edit.btn.piecemark.entry',
  receiveIdnums: 'sts.edit.btn.receive.id.nums',
  shipIdNums: 'sts.edit.btn.ship.id.nums',
  moveIdNums: 'sts.edit.btn.move.id.nums',
  qcHolds: 'sts.edit.btn.place.remove.qc.holds',
  partInspection: 'sts.edit.btn.part.inspection',
  editCategoryCodes: 'sts.edit.btn.edit.category.codes',
  unitsOfMeasure: 'sts.edit.btn.units.of.measure.codes',
  editEndConditions: 'sts.edit.btn.edit.end.conditions',
  editWeldNumber: 'sts.edit.btn.edit.weld.numbers',
  enterReaderSheets: 'sts.edit.btn.enter.reader.sheets',
  actualWeldHours: 'sts.edit.btn.enter.actual.weld.hours',
  editCostEach: 'sts.edit.btn.calc.cost.each',
  loadPcmk: 'sts.edit.btn.edit.job.idfile.load.pcmk',
  sequenceNumber: 'sts.edit.btn.change.sequence.num',
  lanSequenceNum: 'sts.edit.btn.lan.sequence.num',
  moveDataToHistory: 'sts.edit.btn.move.data.to.history',
  editDataToArchive: 'sts.edit.btn.move.data.to.archive',
  activeRecordDeletes: 'sts.edit.btn.active.record.delete',
  deletedRecords: 'sts.edit.btn.recall.deleted.records',
  purgeDeletedRecords: 'sts.btn.records.purge',
  viewLoadInformation: 'sts.view.btn.view.load.information',
  piecemarkInfo: 'sts.view.btn.piecemark.info',
  piecemarkFind: 'sts.view.btn.find.piecemark',
  historicalInfo: 'sts.view.btn.view.history.load.info',
  packingListReport: 'sts.btn.reports.packing.list.report',
  receivingFormat: 'sts.btn.reports.receiving.report',
  foxFireRep: 'sts.btn.reports.foxfire.reports',
  statusReport: 'sts.btn.reports.status.report',
  percentComplete: 'sts.btn.reports.percent.complete',
  readerSheets: 'sts.btn.reports.reader.sheets',
  productivityRep: 'sts.btn.reports.productivity.report',
  idLabels: 'sts.btn.reports.bar.code.id.labels',
  partLabels: 'sts.btn.reports.part.labels',
  barCodeLabels: 'sts.window.bar.code.labels.raw',
  defaultHelp: 'sts.default.btn.help',
  whoIAm: 'sts.default.btn.who.am.i',
  preferences: 'sts.default.btn.preferences',
  viewLog: 'sts.default.btn.view.log',
  licenseInfo: 'sts.default.btn.view.logon.license.info',
  i18n: 'sts.label.i18n',
  reindexTables: 'sts.btn.reindex.tables',
  resorePrefs: 'sts.btn.restore.prefs',
  dbChanges: 'sts.btn.db.changes',
  crossoverData: 'sts.btn.crossover.data',
  columnSelection: 'sts.tab.status_report.column_selection',
  jobSelection: 'sts.tab.status_report.job_selection',
  fieldsLabel: 'sts.default.btn.label.fields',
  backupDatabase: 'sts.btn.backup.database',
  dataPath: 'sts.tab.data.paths',
  Hardware: 'sts.tab.hardware',
  Display: 'sts.tab.display',
  POInfo: 'sts.tab.po.info',
  miscInfo: 'sts.tab.misc.info',
  Sounds: 'sts.tab.sounds',
  Wireless: 'sts.tab.wireless',
  MaterialType: 'sts.tab.material.type',
  PowerFab: 'sts.tab.power.fab',
  SDS2: 'sts.tab.sds',
  CategoryEntry: 'sts.tab.cow.category.entry',
  NeedingCodes: 'sts.tab.cow.needing.codes',
  ExistingPCmarkCodes: 'sts.tab.cow.existing.pc.mark.codes',
  InternalCodes: 'sts.tab.cow.internal.codes',
};

export default ScreenId;
